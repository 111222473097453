require('./handlers/header/header')

let headerHeight = 0
const header = document.getElementsByClassName('js-header')[0],
  countHeight = function() {
		headerHeight = 0
		Array.from(header.children).forEach(function (e) {
		  headerHeight += e.clientHeight
		})
		document.querySelector(':root').style.setProperty('--header-height', headerHeight + 'px')
		return headerHeight
	},
  cbHeader = function(mutationsList, observer) {
    for(const mutation of mutationsList) {
      if (mutation.target.classList[0] != 'header') {
        countHeight();
      }
    }
  },
  obsHeader = new MutationObserver(cbHeader)

obsHeader.observe(header, {attributes: true, childList: true, subtree: true})
window.addEventListener('load', function() {
  setTimeout(function () {
  	headerSticky.init('.js-header', countHeight())
  }, 0)
})

